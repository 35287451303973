import { useEffect } from 'react';


const InactivityLogoutTimer = () => {

    const updateExpiryTime = () => {
        console.log('Update function runs');
        const expireTime = Date.now() + (2 * 60 * 60 * 1000);
        localStorage.setItem('expireTime', expireTime.toString());
    };

    const checkForInactivity = () => {
        console.log('Inactivity check function runs');
        const expireTimeString = localStorage.getItem('expireTime');
        const expireTime = expireTimeString ? parseInt(expireTimeString) : 0;
        if (expireTime === 0) {
            updateExpiryTime();
        }

        if (window.location.pathname !== '/' && expireTime !== 0 && expireTime < Date.now()) {
            localStorage.clear();
            window.location.href='/';
        }
    };

    useEffect(() => {
        checkForInactivity();
    }, []);



    useEffect(() => {
        console.log('Event function runs');
        const events = ['click', 'keypress', 'scroll', 'load'];
        const expireTime = localStorage.getItem('expireTime');

        var date = new Date(Date.now()).getDate();
        if (expireTime !== null) {
            date = new Date(parseInt(expireTime)).getDate();
        }

        const update = () => {
            if (date === new Date(Date.now()).getDate() && (expireTime !== null && parseInt(expireTime) > Date.now())) {
                console.log('Update expirytime');
                updateExpiryTime();
            }
        };


        events.forEach(event => window.addEventListener(event, update));

        return () => {
            //events.forEach(event => document.removeEventListener(event, update));
        };
    }, []);

    return null; 
  };

  export default InactivityLogoutTimer;